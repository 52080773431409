<!--
 * @FileDescription: 自定义LineItem组件
 * @Author: 朱建波
 * @Date: 2023-11-27
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZLineItem
 *
 * @Props
 * @property {Array} label                 label标签
 * @property {String} value                value值
 * @property {Number} align                对齐方式  horizontal 水平 vertical 垂直
 * @property {Number} labelWidth           标签宽度
 * @property {Number} labelAlign           标签内容水平对齐方式
 * @property {Number} labelVerticalAlign   标签内容垂直对齐方式
 * @property {Number} labelColor           标签内容颜色
 * @property {Number} color                value内容颜色
 * @property {Number} cssStyle             列的样式
 * @property {Number} labelStyle           标签样式
 * @property {Number} valueStyle           value样式
 * @property {String} custom               自定义类
 *
 * @Slots
 * default                                 默认内容
 *
 * @Methods
-->
<template>
  <div :class="['z-line-item', align]" :style="newCssStyle">
    <div class="label" :style="newLabelStyle">{{ label }}</div>
    <div class="value" :style="newValueStyle" :class="custom">
      <slot>{{ value }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    align: { type: String, default: 'aa' },
    labelWidth: { type: String, default: '' },
    labelAlign: { type: String, default: '' },
    labelVerticalAlign: { type: String, default: '' },
    labelColor: { type: String, default: '' },
    color: { type: String, default: '' },
    cssStyle: { type: Object, default: () => ({}) },
    labelStyle: { type: Object, default: () => ({}) },
    valueStyle: { type: Object, default: () => ({}) },
    custom: { type: String, default: '' }
  },
  inject: {
    width: { value: 'width', default: '' },
    align: { value: 'align', default: '' },
    lc: { value: 'lc', default: '' },
    cc: { value: 'cc', default: '' },
    ls: { value: 'ls', default: () => ({}) },
    vs: { value: 'vs', default: () => ({}) }
  },
  computed: {
    newCssStyle() {
      return {
        alignItems: this.labelVerticalAlign,
        ...this.cssStyle
      }
    },
    newLabelStyle() {
      return {
        width: this.labelWidth || this.width,
        textAlign: this.labelAlign || this.align,
        color: this.labelColor || this.lc,
        ...this.ls,
        ...this.labelStyle
      }
    },
    newValueStyle() {
      return {
        color: this.color || this.cc,
        ...this.vs,
        ...this.valueStyle
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.z-line-item {
  margin: 8rem 0;
  padding-right: 20rem;
  line-height: 40rem;
  display: flex;
  .label {
    width: 130rem;
    margin-right: 20rem;
    flex-shrink: 0;
  }
}
.z-line-wrapper {
  $color1: #333;
  $color2: #999;
  &.bold {
    .value { font-weight: 600; }
  }
  &.primary {
    $color1: #3c3c3c;
    $color2: #1E3AA2;
    .label { color: $color1; }
    .value { color: $color2; }
  }
  &:not(.primary) {
    &:not(.reverse) {
    .label { color: $color2; }
    .value { color: $color1; }
    }
    &.reverse {
      .label { color: $color1; }
      .value { color: $color2; }
    }
  }

  &.vertical {
    .z-line-item {
      flex-direction: column;
    }
    .label {
      width: auto;
      margin-bottom: 8rem;
      line-height: 26rem;
    }
  }
  &:not(.vertical) {
    &.label-v-center {
      .z-line-item {
        align-items: center;
      }
    }
    &.label-v-end {
      .z-line-item {
        align-items: end;
      }
    }
  }
}
</style>
